<template>
  <Dialog
    v-if="visible"
    :value="visible"
    :options="dialogOptions.dialog"
    @set:value="
      (e) =>
        visible= false
    "
  >
    <v-row>
      <v-col
        :cols="12"
        style="display: flex; justify-content: center; align-items: center"
      >
        <iframe
          ref="video"
          :src="`${$businessApi}/monitor/video/video-play/live?videoUrl=${formatVideoUrl}`"
          width="1172px"
          height="619px"
          frameborder="0"
        ></iframe>
      </v-col>
      <v-col
        v-if="isScreenshot"
        :cols="12"
        style="display: flex; justify-content: center; align-items: center"
      >
        <img
          src="@/assets/screen/serviceSupervision/screenshot.png"
          alt="截图"
          style="cursor: pointer"
          @click="
            $refs.video.contentWindow.postMessage(
              { type: 'screenshot', callback: 'onScreenshot' },
              '*'
            )
          "
        />
      </v-col>
    </v-row>
    <ViolationHandlingDialog
      ref="violationHandlingDialog"
      @success="visible = false"
    ></ViolationHandlingDialog>
  </Dialog>
</template>

<script>
import {assiginDeep} from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import ViolationHandlingDialog from './ViolationHandlingDialog.vue';

export default {
  //组件注册
  components: {
    Dialog,
    ViolationHandlingDialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      isScreenshot: false,
      remoteInfo: {},
      visible: false,
      timer: null,
      dialogOptions: {
        dialog: {
          persistent: false,
          width: 1300,
          title: '摄像头监控画面',
        },
      },
    };
  },
  //计算属性
  computed: {
    formatVideoUrl() {
      return this.remoteInfo && this.remoteInfo.cameraLink
        ? encodeURIComponent(this.remoteInfo.cameraLink)
        : 'https://rtmp01open.ys7.com:9188/v3/openlive/E46578731_1_1.flv?expire=1682079270&id=439157345787080704&t=feae38b97857b1a9a6abc5b06885a560d40926e5a22e11df913a9f7a36b63cbe&ev=100';
    },
  },
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.$bus.$emit('global-refresh');
        this.remoteInfo = {};
        this.isScreenshot = false;
        clearInterval(this.timer)
      }
    },
  },
  //DOM访问
  mounted() {
    window.addEventListener('message', this.onMessage);
  },
  destroyed() {
    window.removeEventListener('message', this.onMessage);
  },
  //保存方法
  methods: {
    async getRemote(row) {
      try {
        const data = await this.$http.get(
          `/${this.$businessISG}/monitor/serve/remote-start`,
          {
            uuid: row.uuid,
          }
        );
        if (data.code != 200) throw data.message;
        if (!data.data) throw '数据格式错误！';
        this.remoteInfo = data.data[0];
        this.timer = setTimeout(() => {
          this.isScreenshot = true;
        }, 3000);
      } catch (error) {
        if (this.visible) {
          this.kalert({
            type: 'error',
            content: error + '，十五秒后自动关闭',
          });
          this.timer = setTimeout(() => {
            this.visible = false;
          }, 15000)
        }
      }
    },
    onMessage(e) {
      if (e.data.callback) this[e.data.callback](e.data);
    },
    openDialog(options = {}) {
      clearTimeout(this.timer)
      this.timer = null
      this.dialogOptions = assiginDeep(this.dialogOptions, {...options});
      this.visible = true;
      if (this.dialogOptions.row) this.getRemote(this.dialogOptions.row);
    },
    onScreenshot(data) {
      this.$refs.violationHandlingDialog.openDialog({
        imgBase64: data.data,
        remoteInfo: this.remoteInfo,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
